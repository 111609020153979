/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useState, ReactElement,
} from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'semantic-ui-react';

import { getUser, setAuthToken } from '../../services/api';
import NotFound from '../NotFound/NotFound';
import { AuthContext } from '../../context/authContext';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

interface Props {
  admin?: boolean;
}

const AUTH_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const ProtectedRoute = (props: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [userFetchStarted, setUserFetchStarted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    logOutUser,
    setAuthenticated,
    setUser,
    user: ctxUser,
  } = useContext(AuthContext);

  const {
    isLoading: isAuth0Loading,
    isAuthenticated,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    user: auth0User,
  } = useAuth0();

  useEffect(() => {
    const getToken = async (audience: string) => {
      try {
        return await getAccessTokenSilently({ authorizationParams: { audience } });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.error === 'consent_required') {
          return await getAccessTokenWithPopup({ authorizationParams: { audience } });
        }
        if (e.error === 'login_required') {
          await loginWithPopup({ authorizationParams: { audience } });
        }
        throw new Error(e);
      }
    };

    const setupAuthContext = async () => {
      try {
        if (isLoading && !userFetchStarted) {
          const tokenRes = await getToken(AUTH_AUDIENCE);
          if (!tokenRes) {
            throw new Error('Failed to get token');
          }

          setAuthToken(tokenRes);

          setUserFetchStarted(true);
          const user = await getUser();
          setAuthenticated(true);
          setUser(user);
        }
      } catch (error: any) {
        console.error('Login failed:', error);
        if (error.error === 'access_denied') {
          logOutUser();
        } else if (error.response && error.response.status === 403) {
          setErrorMessage('You do not have the required permissions to view or use this application.');
        } else {
          setErrorMessage('Apologies, something went wrong. Please try again. If the problem persists please contact support.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (isAuthenticated) {
      setupAuthContext();
    }
  }, [
    auth0User,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isAuthenticated,
    isLoading,
    logOutUser,
    loginWithPopup,
    setAuthenticated,
    setUser,
    userFetchStarted,
  ]);

  useEffect(() => {
    if (!isAuth0Loading && !isAuthenticated) {
      navigate('/login');
    }
  }, [isAuth0Loading, isAuthenticated, navigate]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/offers');
    }
  }, [location.pathname, navigate]);

  const { admin } = props;

  if (errorMessage) {
    return <ErrorMessage message={errorMessage} />;
  }

  if (isLoading || isAuth0Loading) {
    return <Loader content="Loading" active />;
  }

  const adminCheck = (): boolean => !!(!admin || (admin && ctxUser?.admin));

  return isAuthenticated && adminCheck()
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ? <Outlet />
    : <NotFound />;
};

ProtectedRoute.defaultProps = {
  admin: undefined,
};

export default ProtectedRoute;
